<template>
  <div>
    <Header title="告警详情" color="#666" />
    <van-cell-group class="mgt10">
      <van-cell title="告警名称：" :value="detail.title" center />
      <van-cell title="站点名称：" :value="detail.siteName" center />
      <van-cell title="设备名称：" :value="detail.equipmentName" center />
      <van-cell title="告警状态：" center>
        <template>
          <van-tag :type="getByStatus(detail.alarmStatus)">{{
            detail.alarmStatus
          }}</van-tag>
        </template>
      </van-cell>
      <van-cell title="处理状态：" center>
        <template>
          <van-tag :type="getByStatus(detail.status)">{{
            detail.status
          }}</van-tag>
        </template>
      </van-cell>
      <van-cell title="告警级别：" :value="detail.alarmLevel" center />
      <!-- <van-cell title="告警分类：" :value="detail.alarmType" center /> -->
      <!-- <van-cell title="告警区分：" :value="detail.alarmDifferent" center /> -->
      <!-- <van-cell title="紧急程度：" :value="detail.alarmDegree" center /> -->
      <van-cell
        title="告警时间："
        :value="parseTime(detail.alarmTime)"
        center
      />

      <!-- 
      <van-cell title="现场图片：">
        <template #label>
          <van-image
            v-for="(item, index) in imglist"
            class="mgr10"
            :key="index"
            width="100"
            height="100"
            :src="item"
            @click="showImagePreview(imglist, index)"
          />
        </template>
      </van-cell> -->
    </van-cell-group>
    <!-- <van-cell-group class="mgt10">
      <van-cell title="站点影响：" :label="detail.impactSite" />
      <van-cell title="设备影响：" :label="detail.impactEquipment" />
    </van-cell-group> -->
    <van-cell-group class="mgt10">
      <van-cell title="告警描述：" :label="detail.content" />
    </van-cell-group>


  </div>
</template>
<script>
import { getGaojingDetail } from "@/api/gaojing";
import { Toast, Cell, CellGroup, Image, ImagePreview, Tag } from "vant";
import Header from "@/components/header";

export default {
  name: "GaojingDetail",
  components: {
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Image.name]: Image,
    [Tag.name]: Tag,
    [ImagePreview.Component.name]: ImagePreview.Component,
    Header
  },
  data() {
    return {
      loading: false,
      detail: {},
      imglist: []
    };
  },
  computed: {},
  created() {
    this.parseTime = this.utils.parseTime;
    const id = this.$route.query.id;
    if (isNaN(id)) {
      return Toast.fail("参数错误！");
    }
    this.id = id;
    this.getGaojingDetail();
  },
  mounted() { },
  updated() { },
  methods: {
    getGaojingDetail() {
      Toast.loading("加载中...");
      const id = this.id;
      getGaojingDetail({ id: id })
        .then(res => {
          this.detail = res.data;
          if (res.data.files) {
            const fileList = JSON.parse(res.data.files);
            this.imglist = fileList.map(item => {
              return process.env.VUE_APP_BASE_API + item.id;
            });
          }
          Toast.clear();
        })
        .catch(err => {
          console.log(err);
        });
    },
    //图片预览
    showImagePreview(imglist, index) {
      ImagePreview({
        images: imglist,
        startPosition: index
      });
    },
    //根据类型获取状态
    getByStatus(status) {
      if (status == "告警中" || status == "未处理") {
        status = 0;
      } else if (status == "处理中") {
        status = 2;
      } else {
        status = 1;
      }
      const statusArr = ["danger", "success", "warning"];
      return statusArr[status];
    }
  }
};
</script>
<style lang="scss" scoped>

.card-wrap {
				text-align: center;
			}

			.news1 {
				width: 400px;
				height: 500px;
				position: relative;
				background: skyblue;
				overflow: visible;
				margin: 20px;
				display: inline-block;
			}

			.ribbon {
				width: 106px;
				height: 108px;
				overflow: hidden;
				position: absolute;
				top: -6px;
				left: -6px;
			}

			.ribbon1 {
				line-height: 18px;
				text-align: center;
				transform: rotate(-45deg);
				position: relative;
				padding: 8px 0;
				left: -33px;
				top: 26px;
				width: 150px;
				background: goldenrod;
				color: white;
				box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
				letter-spacing: 1px;
			}

			.ribbon1:before,
			.ribbon1:after {
				content: "";
				border-top: 4px solid #4e7c7d;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				position: absolute;
				bottom: -4px;
			}

			.ribbon1:before {
				left: 0;
			}

			.ribbon1:after {
				right: 0;
			}

.form /deep/ {
  padding: 20px 0px;
  .btn {
    width: 175px;
    margin: 20px 100px;
  }
  .van-field__label {
    flex: none;
    width: 75px;
  }
  .van-uploader__preview-image {
    width: 75px;
    height: 75px;
  }
}
</style>